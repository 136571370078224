import "react-calendar/dist/Calendar.css";

import React from "react";
import { useState } from "react";
import { Calendar } from "react-calendar";
import FilterHOC from "./FilterHOC";

const CustomDate = ({ date, setDate }) => {
  const [status, setStatus] = useState(false);

  const formatDate = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return `${dd}-${mm}-${yyyy}`;
  };

  return (
    <FilterHOC status={status} setStatus={setStatus}>
      <span className="label">
        Date:{" "}
        {`${formatDate(date?.[0] || date)} - ${formatDate(date?.[1] || date)}`}
      </span>

      <label
        className="label-btn"
        htmlFor="status"
        onClick={() => setStatus(!status)}
      ></label>

      <div className="popup-menu popup-menu--date">
        <Calendar
          onChange={setDate}
          value={date}
          returnValue={"range"}
          selectRange={true}
          calendarType="US"
        />
      </div>
    </FilterHOC>
  );
};

export default CustomDate;
