/* eslint-disable no-unused-vars */
import { useLazyQuery } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate } from "react-router-dom";
import { GET_ALL_SELLER_APPLICATIONS } from "../../../../queries/sellers/queries";
import Preloader from "../../../Base/Preloader";
import CustomDate from "../../../Base/Filters/CustomDate";
import CustomSelect from "../../../Base/Filters/CustomSelect";
import Status from "../../../Base/Filters/CustomStatus";
import CustomMultiRangeSlider from "../../../Base/Filters/CustomMultiRangeSlider";

const SellersApplicationContainer = () => {
  const [countRows, setCountRows] = useState(10);
  const [page, setPage] = useState(1);

  const [date, setDate] = useState(new Date());

  const [statuses, setStatuses] = useState([
    { value: "new", label: "New", status: true },
    { value: "on_review", label: "On review", status: true },
    { value: "need_info", label: "Need info", status: true },
    { value: "rejected", label: "Rejected", status: true },
    { value: "approved", label: "Approved", status: true },
  ]);

  const [getAllSellerApplications, { loading, data }] = useLazyQuery(
    GET_ALL_SELLER_APPLICATIONS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    getAllSellerApplications({
      variables: {
        page: page,
        limit: countRows,
        dateStart: date[0]
          ? new Date(date[0]).toLocaleDateString().replace(/\./g, "/")
          : null,
        dateEnd: date[1]
          ? new Date(date[1]).toLocaleDateString().replace(/\./g, "/")
          : null,
        status: [...statuses.filter((s) => s.status).map((s) => s.value)],
      },
    });
  }, [page, countRows, statuses, date]);

  return (
    <SellersApplication
      date={date}
      setDate={setDate}
      countRows={countRows}
      page={page}
      setPage={setPage}
      loading={loading}
      data={data?.sellerApplications}
      statuses={statuses}
      setStatuses={setStatuses}
    />
  );
};

const SellersApplication = ({
  date,
  setDate,
  countRows,
  page,
  setPage,
  loading,
  data,
  statuses,
  setStatuses,
}) => {
  const navigate = useNavigate();

  return loading ? (
    <Preloader />
  ) : (
    <>
      <div className="main-box__bread-crumbs">
        <NavLink to="/profile/sellers_applications">
          Sellers' applications
        </NavLink>
      </div>

      <h2 className="main-box__title">Sellers' Applications</h2>

      <div className="main-header">
        <div className="main-header__filters">
          <CustomSelect
            name={"Status"}
            value={statuses}
            setValue={setStatuses}
          />
          <CustomDate date={date} setDate={setDate} />
        </div>
        <div className="main-header__searcher">
          {/* <form className="searcher">
            <input
              type="text"
              className="input input--searcher"
              placeholder="Search exact name, company etc..."
            />

            <button className="searcher-loupe">
              <img src="/images/icons/loupe.svg" />
            </button>
          </form> */}
        </div>
      </div>
      <div className="main-content main-table">
        <div className="table--main">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Company name</th>
                <th>Country</th>
                <th>City</th>
                <th>Assignee</th>
                <th>KYC status</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.collection?.length ? (
                data?.collection.map((e, i) => (
                  <tr key={i} onClick={() => navigate(`${e.id}`)}>
                    <td>{`${e?.user?.firstName} ${e?.user?.lastName}`}</td>
                    <td>
                      {new Date(e.createdAt)
                        .toLocaleDateString()
                        .replaceAll(".", "/")}
                    </td>
                    <td>{e.legalBusinessName}</td>
                    <td>{e.country}</td>
                    <td>{e.region}</td>
                    <td>
                      {e.underwriter
                        ? `${e.underwriter?.firstName || "-"} ${
                            e.underwriter?.lastName || "-"
                          }`
                        : "Not assigned"}
                    </td>
                    <td>{`${e?.user?.kycVerificationStatus}`}</td>
                    <td>
                      <Status id={e.id} status={e.status.toLowerCase()} />
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  <tr>
                    <td colSpan={7}>No data to show</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="main-footer">
        <div className="main-footer__number">
          <p className="txt">View</p>
          <input
            type="text"
            className="input input--number"
            value={countRows}
            disabled
          />
          <p className="txt">records per page</p>
        </div>
        <div className="main-footer__pages">
          {/* <p className="txt">Showing 1 to 50 of 2,210 entries</p> */}

          {data?.metadata?.totalPages > 1 && (
            <ReactPaginate
              nextLabel="Next"
              initialPage={page - 1}
              onPageChange={(e) => {
                setPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={data?.metadata?.totalPages || 1}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              containerClassName={
                "main-footer__pages-controller page-controller"
              }
              pageClassName={"page-controller__num"}
              pageLinkClassName={"link"}
              activeClassName={"page-controller__num--active"}
              previousClassName={
                "page-controller__num page-controller__num--prev"
              }
              nextClassName={"page-controller__num page-controller__num--next"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(SellersApplicationContainer);
