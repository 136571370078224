import React from "react";

const ComingSoon = () => {
  return (
    <div className="main-content">
      <h3 className="main-content__title">Coming soon...</h3>
      <hr className="line" />
    </div>
  );
};

export default ComingSoon;
