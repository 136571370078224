/* eslint-disable no-unused-vars */
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { encryptor } from "../encryptor";
import types from "../types";

const initialState = {
  loader: false,
  data: {
    token: "",
    user_id: 0,
  },
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.UPDATE_TOKEN:
      return {
        ...state,
        data: {
          ...state.data,
          token: payload.token,
        },
      };

    case types.SIGN_IN_START:
    case types.SIGN_UP_START:
      return {
        ...state,
        loader: true,
      };

    case types.SIGN_IN_FAILURE:
    case types.SIGN_UP_FAILURE:
      return {
        ...state,
        loader: false,
      };

    case types.SIGN_IN_SUCCESS:
    case types.SIGN_UP_SUCCESS:
      return {
        ...state,
        loader: false,
        data: {
          ...payload,
        },
      };

    case types.FIRST_SIGN_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          first_sign: false,
        },
      };

    case types.SIGN_OUT_START: {
      return {
        ...state,
        loader: true,
      };
    }

    case types.SIGN_OUT_FAILURE: {
      return {
        ...state,
        loader: false,
      };
    }

    case types.LOAD_USER_FAILURE:
    case types.SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }

    // case types.LOAD_USER_START: {
    //   return {
    //     ...state,
    //     loader: true,
    //   };
    // }

    // case types.LOAD_USER_FAILURE: {
    //   return {
    //     ...state,
    //     loader: false,
    //   };
    // }

    case types.LOAD_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        data: {
          ...state.data,
          ...payload,
        },
      };
    }

    default:
      return state;
  }
};

const authConfig = {
  key: "amnapay_auth",
  storage,
  blacklist: ["loader"],
  transforms: [encryptor],
};

export default persistReducer(authConfig, authReducer);
