/* eslint-disable no-unused-vars */
import React from "react";
import { motion } from "framer-motion";
import { Navigate, Outlet } from "react-router-dom";
import ComingSoon from "../components/Base/ComingSoon";
import RecoverPass from "../components/Pages/Auth/ForgotPassword/Recover";
import Temporary from "../components/Pages/Auth/ForgotPassword/Temporary";
import SignIn from "../components/Pages/Auth/SignIn";
import SignUp from "../components/Pages/Auth/SignUp";
import StartProfile from "../components/Pages/Auth/StartProfile";
import EmailConfirmation from "../components/Pages/Auth/StartProfile/EmailConfirmation";
import Account from "../components/Pages/Profile/Account";
import Cards from "../components/Pages/Profile/Cards";
import Home from "../components/Pages/Profile/Home";
import Payments from "../components/Pages/Profile/Payments";
import Products from "../components/Pages/Profile/Products";
import Add from "../components/Pages/Profile/Products/Add";
import ProductSummary from "../components/Pages/Profile/Products/Summary";
import SellersApplication from "../components/Pages/Profile/SellersApplication";
import Transactions from "../components/Pages/Profile/Transactions";
import API from "../components/Pages/Settings/API";
import Company from "../components/Pages/Settings/Company";
import Integrations from "../components/Pages/Settings/Integrations";
import Notification from "../components/Pages/Settings/Notification";
import Personal from "../components/Pages/Settings/Personal";
import Security from "../components/Pages/Settings/Security";
import Summary from "../components/Pages/Settings/Summary";
import Team from "../components/Pages/Settings/Team";
import CreateNewPassword from "../components/Pages/Auth/ForgotPassword/CreateNewPassword";

export const MotionComponent = (props) => {
  const baseVariant = {
    initial: {
      opacity: 0,
    },

    in: {
      opacity: 1,
    },

    out: {
      opacity: 0,
    },
  };

  return (
    <motion.div
      transition={{
        y: { type: "spring", stiffness: 500, damping: 200 },
      }}
      initial="initial"
      animate="in"
      exit="out"
      variants={baseVariant}
      className={`page${props?.className ? ` ${props.className}` : ""}`}
    >
      {props.children}
    </motion.div>
  );
};

const otherRoutes = () => [
  {
    path: "/auth/start-profile",
    element: (
      <MotionComponent>
        <StartProfile />
      </MotionComponent>
    ),
  },

  {
    path: "*",
    element: (
      <MotionComponent>
        <Navigate to="/auth/start-profile" />
      </MotionComponent>
    ),
  },
];

const publicRoutes = () => [
  {
    path: "/",
    element: (
      <MotionComponent>
        <SignUp />
      </MotionComponent>
    ),
  },
  {
    path: "auth",
    exact: true,
    element: (
      <MotionComponent>
        <Outlet />
      </MotionComponent>
    ),
    children: [
      {
        path: "confirmation/:confirmationToken",
        element: (
          <MotionComponent>
            <EmailConfirmation />
          </MotionComponent>
        ),
      },
      {
        path: "signup",
        element: (
          <MotionComponent>
            <SignUp />
          </MotionComponent>
        ),
      },
      {
        path: "signin",
        element: (
          <MotionComponent>
            <SignIn />
          </MotionComponent>
        ),
      },
      {
        path: "forgot-password",
        children: [
          {
            path: "",
            element: (
              <MotionComponent>
                <RecoverPass />
              </MotionComponent>
            ),
          },
          {
            path: "temporary/:email",
            element: (
              <MotionComponent>
                <Temporary />
              </MotionComponent>
            ),
          },
          {
            path: "new-password/:token",
            element: (
              <MotionComponent>
                <CreateNewPassword />
              </MotionComponent>
            ),
          },
        ],
      },
      {
        path: "",
        element: (
          <MotionComponent>
            <SignUp />
          </MotionComponent>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <MotionComponent>
        <Navigate to="/" />
      </MotionComponent>
    ),
  },
];

const privateRoutes = () => [
  {
    path: "/",
    element: (
      <MotionComponent>
        <Navigate to={"/profile"} />
      </MotionComponent>
    ),
  },

  {
    path: "profile",
    children: [
      {
        path: "transactions",
        element: (
          <MotionComponent>
            <Transactions />
          </MotionComponent>
        ),
      },
      {
        path: "payments",
        // element: <Payments />,
        element: (
          <MotionComponent>
            <ComingSoon />
          </MotionComponent>
        ),
      },
      {
        path: "cards",
        // element: <Cards />,
        element: (
          <MotionComponent>
            <ComingSoon />
          </MotionComponent>
        ),
      },
      {
        path: "account",
        // element: <Account />,
        element: (
          <MotionComponent>
            <ComingSoon />
          </MotionComponent>
        ),
      },
      {
        path: "products",
        children: [
          {
            path: "",
            element: (
              <MotionComponent>
                <Products />
              </MotionComponent>
            ),
            // element: (
            //   <MotionComponent>
            //     <ComingSoon />
            //   </MotionComponent>
            // ),
          },
          {
            path: "add",
            element: (
              <MotionComponent>
                <Add />
              </MotionComponent>
            ),
          },
          {
            path: ":id",
            element: (
              <MotionComponent>
                <ProductSummary />
              </MotionComponent>
            ),
          },
        ],
      },
      {
        path: "sellers_applications",
        children: [
          {
            path: "",
            element: (
              <MotionComponent>
                <SellersApplication />
              </MotionComponent>
            ),
          },
          {
            path: ":id",
            element: (
              <MotionComponent>
                <Summary underwriter={true} />
              </MotionComponent>
            ),
          },
        ],
      },
      {
        path: "",
        element: (
          <MotionComponent>
            <Home />
          </MotionComponent>
        ),
      },
    ],
  },

  {
    path: "settings",
    children: [
      {
        path: "personal-profile",
        element: (
          <MotionComponent>
            <Personal />
          </MotionComponent>
        ),

        children: [
          {
            path: "email-confirmation/:token",
            element: (
              <MotionComponent>
                <Personal />
              </MotionComponent>
            ),
          },
        ],
      },
      {
        path: "company-profile",
        element: (
          <MotionComponent>
            <Summary />
          </MotionComponent>
        ),
      },
      {
        path: "company-profile/business-details",
        element: (
          <MotionComponent>
            <Company />
          </MotionComponent>
        ),
      },
      {
        path: "integrations",
        element: (
          <MotionComponent>
            <ComingSoon />
          </MotionComponent>
        ),
        // element: <Integrations />,
      },
      {
        path: "api",
        element: (
          <MotionComponent>
            <ComingSoon />
          </MotionComponent>
        ),
        // element: <API />,
      },
      {
        path: "team",
        element: (
          <MotionComponent>
            <ComingSoon />
          </MotionComponent>
        ),
        // element: <Team />,
      },
      {
        path: "notification",
        element: (
          <MotionComponent>
            <ComingSoon />
          </MotionComponent>
        ),
        // element: <Notification />,
      },
      {
        path: "security",
        element: (
          <MotionComponent>
            <ComingSoon />
          </MotionComponent>
        ),
        // element: <Security />,
      },
      {
        path: "",
        element: (
          <MotionComponent>
            <Navigate to="/settings/personal-profile" />
          </MotionComponent>
        ),
      },
    ],
  },

  {
    path: "*",
    element: (
      <MotionComponent>
        <Navigate to="/profile" />
      </MotionComponent>
    ),
  },
];

export default { publicRoutes, privateRoutes, otherRoutes };
