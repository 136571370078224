import { useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { GENERATE_FORGOT_PASSWORD_TOKEN } from "../../../../../queries/auth/queries";
import { Input } from "../../../../../utils/formcontrol";
import Yup from "../../../../../utils/validators";

import RollBack from "../../../../Base/RollBack";

const RecoverPassContainer = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const [generateForgotPasswordToken, { loading }] = useMutation(
    GENERATE_FORGOT_PASSWORD_TOKEN,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );

  const onSubmit = useCallback(({ email }) => {
    generateForgotPasswordToken({
      variables: {
        input: {
          email,
        },
      },
      onCompleted: ({ generateForgotPasswordToken: { success, errors } }) => {
        if (success) {
          navigate(`/auth/forgot-password/temporary/${email}`);
        } else {
          setError(errors?.[0]?.message || "Unknow error");
        }
      },
      onError: (error) => {
        setError(JSON.stringify(error));
      },
    });
  }, []);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Field is required").email(),
  });

  return (
    <RecoverPass
      loading={loading}
      error={error}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    />
  );
};

const RecoverPass = ({
  loading,
  error,
  onSubmit,
  initialValues,
  validationSchema,
}) => {
  return (
    <>
      <RollBack />

      <div className="box box--indent">
        <NavLink to={"/"} className="box-logo"></NavLink>

        <h2 className="box-title box-title--small">Recover password</h2>

        <div className="block-mail block-mail--small">
          <p>To recover your password, enter the email you used in sign up</p>
        </div>

        <div className="form-wrapper">
          <Formik
            id="sign_in"
            enableReinitialize
            initialValues={{ ...initialValues }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isValid, touched }) => (
              <form
                onSubmit={handleSubmit}
                name="recover_password"
                className="form"
              >
                {error && (
                  <div className="message-errror">
                    <div className="message-errror__pic"></div>
                    <p>{error}</p>
                  </div>
                )}

                <Field
                  type="text"
                  title={"Email *"}
                  name="email"
                  component={Input}
                  autoComplete="new-password"
                />

                <input
                  type="submit"
                  value="Confirm"
                  className="input input--btn"
                  disabled={
                    !(isValid && Object.keys(touched).length) || loading
                  }
                />
              </form>
            )}
          </Formik>
        </div>

        <div className="box-bottom">
          <div className="form-wrapper">
            <hr className="line" />
            <p className="box-bottom__txt">
              If you have any problem{" "}
              <NavLink to={"/"} className="link txt--blue">
                Contact Us
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(RecoverPassContainer);
