import { useMutation } from "@apollo/client";

import React, { useState } from "react";
import { UPDATE_SELLER_STATUS } from "../../../../queries/sellers/queries";
import { capitalizeFirstLetter } from "../../../../services/helpers";
import FilterHOC from "../../../Base/Filters/FilterHOC";

const Status = ({ statusOnChanged, id, status }) => {
  const [active, setActive] = useState(false);

  const [updateStatus, { loading }] = useMutation(UPDATE_SELLER_STATUS, {
    onCompleted: ({ updateSellerStatus: { success, sellerApplication } }) => {
      if (success) {
        statusOnChanged(sellerApplication.status);
        setActive(false);
      }
    },
    onError: (error) => {
      console.log(JSON.stringify(error));
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const onChangeStatus = (status, e) => {
    e.stopPropagation();
    updateStatus({
      variables: {
        input: {
          sellerApplicationId: `${id}`,
          status,
        },
      },
    });
  };

  return (
    <FilterHOC status={active} setStatus={setActive} disabled={loading}>
      <span className="label">
        Status:{" "}
        {capitalizeFirstLetter((status || "Not created").replace(/_/gi, " "))}
      </span>

      <label
        className="label-btn"
        htmlFor="status"
        onClick={(e) => {
          e.stopPropagation();
          setActive(!active);
        }}
      ></label>

      <div className="popup-menu">
        <div
          className="link popup-menu__item popup-menu__status table-status--new"
          onClick={(e) => onChangeStatus("new", e)}
        >
          New
        </div>
        <div
          className="link popup-menu__item popup-menu__status table-status--need-info"
          onClick={(e) => onChangeStatus("need_info", e)}
        >
          Need info
        </div>
        <div
          className="link popup-menu__item popup-menu__status table-status--on-review"
          onClick={(e) => onChangeStatus("on_review", e)}
        >
          On review
        </div>
        <div
          className="link popup-menu__item popup-menu__status table-status--approved"
          onClick={(e) => onChangeStatus("approved", e)}
        >
          Approved
        </div>
        <div
          className="link popup-menu__item popup-menu__status table-status--rejected"
          onClick={(e) => onChangeStatus("rejected", e)}
        >
          Rejected
        </div>
      </div>
    </FilterHOC>
  );
};

export default React.memo(Status);
