import axios from "axios";
import { store } from "../redux/store";
import types from "../redux/types";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_MAIN_URI}/api/`,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

const getToken = () => {
  const { token } = store.getState().auth.data;
  return token;
};

instance.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token) {
      config.headers["Authorization"] = "Bearer " + getToken();
    }

    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch({
        type: types.SIGN_OUT_SUCCESS,
      });
    }

    return error;
  }
);

export default {
  auth: {
    signin: (data) => instance.post(`sign_in`, data),
    signup: (data) => instance.post(`sign_up`, data),
    signout: () => instance.delete(`sign_out`),
  },
};
