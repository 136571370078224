import { gql } from "@apollo/client";
import {
  CORE_EMAIL_CONFIRMATION_FIELDS,
  CORE_FORGOT_PASSWORD_TOKEN_FIELDS,
  CORE_GENERATE_FORGOT_PASSWORD_TOKEN_FIELDS,
  CORE_UPDATE_FORGOTED_PASSWORD_FIELDS,
} from "./fragments";

export const EMAIL_CONFIRMATION = gql`
  ${CORE_EMAIL_CONFIRMATION_FIELDS}

  mutation emailConfirmation($input: EmailConfirmationMutationInput!) {
    emailConfirmation(input: $input) {
      ...CoreEmailConfirmation
    }
  }
`;

export const GENERATE_FORGOT_PASSWORD_TOKEN = gql`
  ${CORE_GENERATE_FORGOT_PASSWORD_TOKEN_FIELDS}

  mutation generateForgotPasswordToken(
    $input: GenerateForgotPasswordTokenMutationInput!
  ) {
    generateForgotPasswordToken(input: $input) {
      ...CoreGenerateForgotPasswordToken
    }
  }
`;

export const FORGOT_PASSWORD_TOKEN = gql`
  ${CORE_FORGOT_PASSWORD_TOKEN_FIELDS}

  mutation forgotPassword($input: ForgotPasswordMutationInput!) {
    forgotPassword(input: $input) {
      ...CoreForgotPassword
    }
  }
`;

export const UPDATE_FORGOTED_PASSWORD = gql`
  ${CORE_UPDATE_FORGOTED_PASSWORD_FIELDS}

  mutation updateForgotedPassword($input: UpdateForgotedPasswordInput!) {
    updateForgotedPassword(input: $input) {
      ...CoreUpdateForgotedPassword
    }
  }
`;
