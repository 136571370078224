/* eslint-disable no-unused-vars */
import { useMutation } from "@apollo/client";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { UPDATE_SELLER_STATUS } from "../../../queries/sellers/queries";
import { capitalizeFirstLetter } from "../../../services/helpers";

const StatusContainer = ({ id, status }) => {
  const [_status, setStatus] = useState(status);
  const [active, setActive] = useState(false);
  const componentRef = useRef(null);

  const [updateStatus, { loading }] = useMutation(UPDATE_SELLER_STATUS, {
    onCompleted: ({ updateSellerStatus: { success, sellerApplication } }) => {
      if (success) {
        setStatus(sellerApplication.status.toLowerCase());
        setActive(false);
      }
    },
    onError: (error) => {
      console.log(JSON.stringify(error));
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const onChangeStatus = (status, e) => {
    e.stopPropagation();
    updateStatus({
      variables: {
        input: {
          sellerApplicationId: id,
          status,
        },
      },
    });
  };

  useEffect(() => {
    const clickOutside = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [componentRef]);

  return (
    <Status
      loading={loading}
      id={id}
      status={_status}
      active={active}
      setActive={setActive}
      componentRef={componentRef}
      onChangeStatus={onChangeStatus}
    />
  );
};

const Status = ({
  loading,
  status,
  active,
  setActive,
  componentRef,
  onChangeStatus,
}) => {
  return (
    <div
      className={classNames("main-table__main-status", {
        "popup--active": active,
        disabled: loading,
      })}
      ref={componentRef}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          setActive(!active);
        }}
        className={classNames(
          `link table-status table-status--${status
            .toLowerCase()
            .replace(/_/gi, "-")}`
        )}
      >
        {capitalizeFirstLetter((status || "New").replace(/_/gi, " "))}
      </div>

      <div className="popup-menu">
        <div
          className="link popup-menu__item popup-menu__status table-status--new"
          onClick={(e) => onChangeStatus("new", e)}
        >
          New
        </div>
        <div
          className="link popup-menu__item popup-menu__status table-status--need-info"
          onClick={(e) => onChangeStatus("need_info", e)}
        >
          Need info
        </div>
        <div
          className="link popup-menu__item popup-menu__status table-status--on-review"
          onClick={(e) => onChangeStatus("on_review", e)}
        >
          On review
        </div>
        <div
          className="link popup-menu__item popup-menu__status table-status--approved"
          onClick={(e) => onChangeStatus("approved", e)}
        >
          Approved
        </div>
        <div
          className="link popup-menu__item popup-menu__status table-status--rejected"
          onClick={(e) => onChangeStatus("rejected", e)}
        >
          Rejected
        </div>
      </div>
    </div>
  );
};

export default React.memo(StatusContainer);
