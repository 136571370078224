import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { GET_PROFILE } from "../../../../queries/user/queries";
import { Input } from "../../../../utils/formcontrol";
import { useLazyQuery, useMutation } from "@apollo/client";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import types from "../../../../redux/types";
import { EMAIL_CONFIRMATION } from "../../../../queries/auth/queries";

const PersonalContainer = () => {
  const [error, setError] = useState(undefined);
  const dispatch = useDispatch();
  const { token } = useParams();

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password_el: "********",
    role: "",
    phone: "",
  });

  const modalClose = () =>
    dispatch({
      type: types.HIDE_MODAL,
    });

  const [emailConfirmation] = useMutation(EMAIL_CONFIRMATION, {
    onCompleted: ({ emailConfirmation: { success, errors, user } }) => {
      if (success) {
        setInitialValues({
          ...initialValues,
          ...user,
        });
      } else {
        setError(errors[0].message);
      }
    },
    onError: (error) => {
      setError(error[0].message);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const showModalProfileUpdate = () => {
    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "ChangePersonalInformation",
        initialValues: {
          firstName: initialValues.firstName,
          lastName: initialValues.lastName,
        },
        callbacks: {
          success: (data) => {
            setInitialValues({ ...initialValues, ...data });

            dispatch({
              type: types.LOAD_USER_SUCCESS,
              payload: { ...data },
            });

            dispatch({
              type: types.SHOW_MODAL,
              payload: {
                name: "ChangesSaved",
              },
            });
          },
          error: () => {},
          finally: () => {
            modalClose();
          },
        },
      },
    });
  };

  const showModalEmailUpdate = () => {
    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "ChangePersonalEmail",
        callbacks: {
          success: () => {
            dispatch({
              type: types.SHOW_MODAL,
              payload: {
                name: "ChangesSavedEmail",
              },
            });
          },
          error: () => {},
          finally: () => {
            modalClose();
          },
        },
      },
    });
  };

  const showModalPasswordUpdate = () => {
    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "ChangePersonalPassword",
        callbacks: {
          success: () => {
            dispatch({
              type: types.SHOW_MODAL,
              payload: {
                name: "ChangesSaved",
              },
            });
          },
          error: () => {},
          finally: () => {
            modalClose();
          },
        },
      },
    });
  };

  const showModalPhoneUpdate = () => {
    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "ChangePersonalPhone",
        callbacks: {
          success: () => {
            dispatch({
              type: types.SHOW_MODAL,
              payload: {
                name: "ChangesSaved",
              },
            });
          },
          error: () => {},
          finally: () => {
            modalClose();
          },
        },
      },
    });
  };

  // GRAPHQL REQUESTS

  const [loadProfile, { ...query }] = useLazyQuery(GET_PROFILE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      data.profile.phone = data.profile.phone?.number;

      setInitialValues({
        ...initialValues,
        ...data.profile,
      });
    },
  });

  // GRAPHQL REQUESTS

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    if (token) {
      emailConfirmation({
        variables: {
          input: {
            confirmationToken: token,
          },
        },
      });
    }
  }, [token]);

  return (
    <Personal
      error={error}
      initialValues={initialValues}
      loading={query.loading || emailConfirmation.loading}
      showModalProfileUpdate={showModalProfileUpdate}
      showModalEmailUpdate={showModalEmailUpdate}
      showModalPasswordUpdate={showModalPasswordUpdate}
      showModalPhoneUpdate={showModalPhoneUpdate}
    />
  );
};

const Personal = ({
  initialValues,
  loading,
  showModalProfileUpdate,
  showModalEmailUpdate,
  showModalPasswordUpdate,
  showModalPhoneUpdate,
}) => {
  return (
    <>
      <div className="main-box__bread-crumbs">
        <NavLink to="/profile">Profile</NavLink>
      </div>

      <h2 className="main-box__title">Personal Profile</h2>
      <div className="main-content">
        <div className="main-content__title">
          {`${initialValues.firstName || "Amna"} ${
            initialValues.lastName || "Financial"
          }`}
        </div>
        <hr className="line" />
        <Formik
          id="personal_information"
          enableReinitialize
          initialValues={{ ...initialValues }}
        >
          {() => (
            <form name="personal_information" className="main-content__form">
              <h2 className="form__el-headline">
                Personal Information
                <NavLink to="#" onClick={!loading && showModalProfileUpdate}>
                  <img src="/images/icons/pencil.svg" />
                </NavLink>
              </h2>
              <Field
                type="text"
                title={"First Name"}
                name="firstName"
                component={Input}
                disabled
              />
              <Field
                type="text"
                title={"Last Name"}
                name="lastName"
                component={Input}
                disabled
              />
              <h2 className="form__el-headline">
                Email
                <NavLink to="#" onClick={!loading && showModalEmailUpdate}>
                  <img src="/images/icons/pencil.svg" />
                </NavLink>
              </h2>

              <Field
                type="text"
                title={"Email"}
                name="email"
                component={Input}
                disabled
              />

              <h2 className="form__el-headline">
                Password
                <NavLink to="#" onClick={!loading && showModalPasswordUpdate}>
                  <img src="/images/icons/pencil.svg" />
                </NavLink>
              </h2>

              <Field
                type="text"
                title={"Password"}
                name="password_el"
                component={Input}
                disabled
              />

              <h2 className="form__el-headline">
                Phone
                <NavLink to="#" onClick={!loading && showModalPhoneUpdate}>
                  <img src="/images/icons/pencil.svg" />
                </NavLink>
              </h2>

              <Field
                type="text"
                title={"Phone"}
                name="phone"
                component={Input}
                disabled
              />
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default React.memo(PersonalContainer);
