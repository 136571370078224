import { Field, Formik } from "formik";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  Select,
  Input,
  Textarea,
  Photo,
} from "../../../../../utils/s_formcontrol";
import Yup from "../../../../../utils/validators";

const AddProductContainer = () => {
  const initialValues = {
    image: "",
    name: "",
    description: "",
    cost: "",
    discount: "",
    currrency: "",
  };

  const validationSchema = Yup.object().shape({
    image: Yup.string().required("Field is required"),
    name: Yup.string().required("Field is required"),
    description: Yup.string().required("Field is required").max(2000),
    cost: Yup.number().required("Field is required"),
    discount: Yup.number(),
    currrency: Yup.string().required("Field is required"),
  });

  return (
    <AddProduct
      initialValues={initialValues}
      validationSchema={validationSchema}
    />
  );
};

const AddProduct = ({ initialValues, validationSchema, loading }) => {
  return (
    <>
      <div className="main-box__bread-crumbs">
        <NavLink to="/profile/products">Products</NavLink>/
        <NavLink to="/profile/products/add">Add new product</NavLink>
      </div>

      <div className="main-box__title">
        <h2 className="title">Add new product</h2>
      </div>

      <div className="main-content">
        <div className="main-content__title">
          <div className="main-content__title-item">
            <p className="txt">Product name</p>
          </div>
        </div>

        <hr className="line" />

        <Formik
          id="product_add"
          enableReinitialize
          initialValues={{ ...initialValues }}
          validationSchema={{ ...validationSchema }}
        >
          {({ isValid }) => (
            <form name="product_add" className="product-add__form">
              <Field name="image" component={Photo} />

              <Field
                type="text"
                title={"Product`s name *"}
                name="name"
                component={Input}
              />

              <Field
                type="text"
                title={"Product`s description *"}
                name="description"
                component={Textarea}
                maxlength={2000}
              />

              <Field
                type="text"
                title={"Cost *"}
                name="cost"
                component={Input}
              />

              <Field
                type="text"
                title={"Discount"}
                name="discount"
                component={Input}
              />

              <Field
                title={"Currency"}
                name="currency"
                component={Select}
                options={[
                  {
                    name: "USD",
                    value: "USD",
                  },
                  {
                    name: "MAD",
                    value: "MAD",
                  },
                  {
                    name: "SAR",
                    value: "SAR",
                  },
                  {
                    name: "AED",
                    value: "AED",
                  },
                  {
                    name: "GBR",
                    value: "GBR",
                  },
                  {
                    name: "EUR",
                    value: "EUR",
                  },
                ]}
              />
              <div className="alert-block__btns">
                <input
                  type="button"
                  value="Cancel"
                  className="input input--btn input--btn--light"
                  onClick={() => {}}
                />
                <input
                  disabled={!!loading || !isValid}
                  type="submit"
                  value="Confirm"
                  className="input input--btn"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default React.memo(AddProductContainer);
