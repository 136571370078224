/* eslint-disable no-unused-vars */
import { useLazyQuery } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import { GET_ALL_SELLER_APPLICATIONS } from "../../../../queries/sellers/queries";
import Preloader from "../../../Base/Preloader";
import CustomDate from "../../../Base/Filters/CustomDate";
import CustomSelect from "../../../Base/Filters/CustomSelect";
import Status from "../../../Base/Filters/CustomStatus";
import Sider from "./Sider";

const TransactionsContainer = () => {
  const [countRows, setCountRows] = useState(10);
  const [date, setDate] = useState(new Date());
  const [page, setPage] = useState(1);

  const [statuses, setStatuses] = useState([
    { value: "new", label: "New", status: true },
    { value: "on_review", label: "On review", status: true },
    { value: "need_info", label: "Need info", status: true },
    { value: "rejected", label: "Rejected", status: true },
    { value: "approved", label: "Approved", status: true },
  ]);

  const [payments, setPayments] = useState([
    { value: "incoming", label: "Incoming", status: true },
    { value: "outcoming", label: "Outcoming", status: true },
  ]);

  const [getAllSellerApplications, { loading, data }] = useLazyQuery(
    GET_ALL_SELLER_APPLICATIONS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    getAllSellerApplications({
      variables: {
        page: page,
        limit: countRows,
      },
    });
  }, [page, countRows]);

  return (
    <Transactions
      date={date}
      setDate={setDate}
      countRows={countRows}
      page={page}
      setPage={setPage}
      loading={loading}
      data={data?.sellerApplications}
      statuses={statuses}
      setStatuses={setStatuses}
      payments={payments}
      setPayments={setPayments}
    />
  );
};

const Transactions = ({
  date,
  setDate,
  countRows,
  page,
  setPage,
  loading,
  data,
  statuses,
  setStatuses,
  payments,
  setPayments,
}) => {
  return loading ? (
    <Preloader />
  ) : (
    <>
      <div className="main-box__bread-crumbs">
        <NavLink to="/profile/transactions">Transactions</NavLink>
      </div>

      <div className="main-box__title main-box__title--flex">
        <h2 className="title">Transactions</h2>

        <button className="input input--btn input--btn--export">
          Export table
        </button>
      </div>

      <div className="main-header">
        <div className="main-header__filters">
          <CustomSelect
            name={"Status"}
            value={statuses}
            setValue={setStatuses}
          />

          <CustomDate date={date} setDate={setDate} />

          <CustomSelect
            name={"Payments"}
            value={payments}
            setValue={setPayments}
          />
        </div>
      </div>
      <div className="table--sider">
        <div className="main-content main-table">
          <div className="table--main">
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>To / From</th>
                  <th>Amount</th>
                  <th>Account</th>
                  <th>Payment Method</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td colSpan={5}>No data to show</td>
                </tr> */}

                <tr>
                  <td>21/12/2021</td>
                  <td>chase-cheking •••• 4553</td>
                  <td>
                    <span className="transaction--amount">
                      <img
                        src="/images/icons/table/deposit-arrow.svg"
                        className="img"
                      />
                      $ 320
                    </span>
                  </td>
                  <td>chase-cheking •••• 4553</td>
                  <td>transfer in</td>
                </tr>
                <tr>
                  <td>21/12/2021</td>
                  <td>chase-cheking •••• 4553</td>
                  <td>
                    <span className="transaction--amount">
                      <img
                        src="/images/icons/table/withdraw-arrow.svg"
                        className="img"
                      />
                      $ 320
                    </span>
                  </td>
                  <td>chase-cheking •••• 4553</td>
                  <td>transfer out</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* <Sider /> */}
      </div>

      <div className="main-footer">
        <div className="main-footer__number">
          <p className="txt">View</p>
          <input
            type="text"
            className="input input--number"
            value={countRows}
            disabled
          />
          <p className="txt">records per page</p>
        </div>
        <div className="main-footer__pages">
          {/* <p className="txt">Showing 1 to 50 of 2,210 entries</p> */}

          {data?.metadata?.totalPages > 1 && (
            <ReactPaginate
              nextLabel="Next"
              initialPage={page - 1}
              onPageChange={(e) => {
                setPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={data?.metadata?.totalPages || 1}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              containerClassName={
                "main-footer__pages-controller page-controller"
              }
              pageClassName={"page-controller__num"}
              pageLinkClassName={"link"}
              activeClassName={"page-controller__num--active"}
              previousClassName={
                "page-controller__num page-controller__num--prev"
              }
              nextClassName={"page-controller__num page-controller__num--next"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(TransactionsContainer);
