import { gql } from "@apollo/client";
import { CORE_SELLER_APPLICATION_FIELDS } from "../business-structure/fragments";
import {
  CORE_SELLER_APPLICATION_COLLECTION,
  CORE_UPDATE_SELLER_APPLICATION_FIELDS,
} from "./fragments";

// QUERIES

export const GET_SELLER_APPLICATION_BY_ID = gql`
  ${CORE_SELLER_APPLICATION_FIELDS}

  query sellerApplication($id: Int!) {
    sellerApplication(id: $id) {
      ...CoreSellerApplication
    }
  }
`;

export const GET_SELLER_APPLICATION = gql`
  ${CORE_SELLER_APPLICATION_FIELDS}

  query currentSellerApplication {
    currentSellerApplication {
      ...CoreSellerApplication
    }
  }
`;

export const GET_ALL_SELLER_APPLICATIONS = gql`
  ${CORE_SELLER_APPLICATION_COLLECTION}

  query sellerApplications(
    $page: Int
    $limit: Int
    $dateStart: String
    $dateEnd: String
    $status: [String!]
  ) {
    sellerApplications(
      page: $page
      limit: $limit
      dateStart: $dateStart
      dateEnd: $dateEnd
      status: $status
    ) {
      ...CoreSellerApplicationCollection
    }
  }
`;

// MUTATIONS

export const UPDATE_SELLER_STATUS = gql`
  ${CORE_UPDATE_SELLER_APPLICATION_FIELDS}

  mutation updateSellerStatus($input: UpdateSellerStatusMutationInput!) {
    updateSellerStatus(input: $input) {
      ...CoreUpdateSellerStatus
    }
  }
`;
