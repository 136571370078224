/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_KYC_LINK } from "../../../../queries/user/queries";

const HomeSeller = ({ firstName, kycVerificationStatus, approvedSeller }) => {
  const [error, setError] = useState(undefined);

  const [startKycVerification, { loading }] = useLazyQuery(GET_KYC_LINK, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: ({ startKycVerification: { link } }) => {
      window.location.href = link;
    },
    onError: (error) => {
      setError(JSON.stringify(error));
    },
  });

  const onPersonalIdentity = useCallback(() => {
    startKycVerification();
  }, []);

  return (
    <div className="main-content main-content--bricks">
      <div className="main-content--bricks--small bricks-content">
        <h3 className="bricks-title">Company Name</h3>
        <h2 className="bricks-content-title">
          Welcome,
          <span>{firstName}!</span>
        </h2>
        <div className="bricks-content-txt">
          {approvedSeller
            ? !kycVerificationStatus
              ? "Tell us a little more about your self."
              : "Your AmnaPay account is ready for your use. Let's get it funded"
            : "We will check your business details and get back to you"}
        </div>
        {!kycVerificationStatus ||
        kycVerificationStatus === "request.pending" ? (
          <input
            onClick={onPersonalIdentity}
            type="button"
            value="Personal Identity"
            className="input input--btn bricks-btn"
            disabled={!approvedSeller}
          />
        ) : (
          <input
            type="button"
            value="Fund Account"
            className="input input--btn bricks-btn"
          />
        )}
      </div>
      <div className="main-content--bricks--big">
        <h3 className="bricks-title">AmnaPay Balance</h3>
        <div className="graph">
          <h2 className="graph-money">$ 0.00</h2>
          <div className="graph-status graph-status--negative">
            <img
              src="/images/icons/content/arrow-positive.svg"
              className="graph-status__img graph-status__img--positive"
            />
            <img
              src="/images/icons/content/arrow-positive.svg"
              className="graph-status__img graph-status__img--negative"
            />
            <span>$0.00 Today, Dec 23</span>
          </div>
          <div className="graph-body">
            <div className="graph-body__bottom">
              <div>nov 27</div>
              <div>nov 27</div>
              <div>nov 27</div>
              <div>nov 27</div>
              <div>nov 27</div>
              <div>nov 27</div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-content--bricks--medium">
        <h3 className="bricks-title">Money out last 30 days</h3>
        <div className="bricks-content">
          <h2 className="bricks-content__money">$ 0.00</h2>
          <div className="bricks-content__status">
            No outgoing transactions yet.
          </div>
          <div className="bricks-content__links">
            <a href="#" className="link txt--blue bricks-content__links-item">
              View All
            </a>
          </div>
        </div>
      </div>
      <div className="main-content--bricks--medium">
        <h3 className="bricks-title">Money out last 30 days</h3>
        <div className="bricks-content">
          <h2 className="bricks-content__money">$ 0.00</h2>
          <div className="bricks-content__status">
            No outgoing transactions yet.
          </div>
          <div className="bricks-content__links">
            <a href="#" className="link txt--blue bricks-content__links-item">
              View All
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSeller;
