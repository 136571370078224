import React from "react";

const HomeUnderwriter = ({ firstName }) => {
  return (
    <div className="main-content main-content--bricks">
      <div className="main-content--bricks--big underwriter">
        <h2 className="bricks-content-title">
          Welcome,
          <span>{firstName}!</span>
          <img
            src="/images/icons/good-bye.svg"
            className="bricks-content-title__img"
          />
        </h2>
        <div className="bricks-content-txt">
          Let's verify some sellers today!
        </div>
      </div>

      <div className="main-content--bricks--medium">
        <h3 className="bricks-title">
          New sellers' requests for profile verification
        </h3>
        <div className="bricks-content">
          <div className="bricks-content__status">You received</div>
          <h2 className="bricks-content__money">256</h2>

          <div className="bricks-content__links">
            <a href="#" className="link txt--blue bricks-content__links-item">
              View All
            </a>
          </div>
        </div>
      </div>

      <div className="main-content--bricks--medium">
        <h3 className="bricks-title">New product requests for verification</h3>
        <div className="bricks-content">
          <div className="bricks-content__status">You received</div>

          <h2 className="bricks-content__money">7265</h2>

          <div className="bricks-content__links">
            <a href="#" className="link txt--blue bricks-content__links-item">
              View All
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeUnderwriter;
